<template>
  <div class="works">
    <left-menu current="demo-list" />

    <div class="main">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>词曲作品列表</el-breadcrumb-item>
      </el-breadcrumb>

      <el-divider></el-divider>

      <el-row>
        <el-radio-group v-model="filter">
          <el-radio-button :label="0">全部</el-radio-button>
          <el-radio-button :label="1">待上架作品</el-radio-button>
          <el-radio-button :label="2">出售中作品</el-radio-button>
          <el-radio-button :label="4">已出售作品</el-radio-button>
          <el-radio-button :label="3">下架作品</el-radio-button>
        </el-radio-group>
      </el-row>
      <el-table
          v-loading="loading"
          :data="tableList"
          border
          :header-cell-style="{backgroundColor: '#f0f3f9'}"
          style="width: 100%;margin-top: 25px;margin-bottom: 25px">
        <el-table-column
            width="200"
            show-overflow-tooltip
            prop="name"
            label="作品名称">
        </el-table-column>
        <el-table-column
            min-width="120"
            show-overflow-tooltip
            v-slot="{row}"
            label="词作者/词价格">
          {{row.lyricist}} ￥{{row.lyricsPrice}}
        </el-table-column>
        <el-table-column
            min-width="120"
            v-slot="{row}"
            show-overflow-tooltip
            label="曲作者/曲价格">
          {{row.composer}} ￥{{row.opernPrice}}
        </el-table-column>
        <el-table-column
            prop="submitTime"
            width="180"
            :formatter="(row, col, cellValue)=>{return $timestampToString(cellValue)}"
            label="上传时间">
        </el-table-column>
        <el-table-column
            prop="totalStatus"
            v-slot="scope"
            width="100"
            label="状态">
          <div>{{ getStatusByTotalStatus(scope.row.totalStatus) }}</div>

          <div v-if="scope.row.totalStatus === 3" style="display: flex;align-items: center;">
            <span class="off-reason-label">驳回原因</span>
            <el-popover
                style="display: flex;align-items: center;"
                placement="bottom"
                width="200"
                trigger="hover">
              <el-image slot="reference" :src="offReason" style="width: 14px;height: 14px;margin-left: 5px;margin-top: 7px"></el-image>
              <p class="off-reason">{{scope.row.offReason}}</p>
            </el-popover>
          </div>
        </el-table-column>
        <el-table-column
            min-width="100"
            v-slot="scope"
            label="操作">
          <el-button v-show="false" type="text">shit</el-button>
          <el-button v-show="scope.row.totalStatus === 0" type="text" @click="handleSubmit(scope.row.id + '-0')">提交</el-button>
          <el-button v-show="scope.row.totalStatus !== 0" type="text" @click="handleDetail(scope.row.id)">详情</el-button>
          <el-button v-show="scope.row.totalStatus === 1" type="text" @click="handleRevoke(scope.row.id)">撤销</el-button>
          <el-button v-show="scope.row.totalStatus === 4" type="text" @click="handleOffSell(scope.row.id)">下架</el-button>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @current-change="getData()"
          :current-page.sync="page.pageNum"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="page.total"
          style="text-align: center"
      >
      </el-pagination>
    </div>

    <!--  todo 封装  -->
    <el-dialog
        title="申请下架"
        :visible.sync="offSell.dialogVisible"
        :show-close="false"
        center
        :v-loading="offSell.loading"
        width="480px">
      <el-input v-model="offSell.reason" placeholder="输入下架原因"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="offSell.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleOffSellSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import LeftMenu from "../components/LeftMenu"
import message from "../utils/message"
import {getStatusByTotalStatus} from "../utils/utils"
import OffReason from '../assets/off-reason.png'

export default {
  name: "DemoList",
  components: {
    LeftMenu
  },
  data(){
    return {
      offReason: OffReason,
      loading: false,
      filter: 0,
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      offSell: {
        loading: false,
        dialogVisible: false,
        reason: '',
        id: null
      },
      tableList: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      this.loading = true
      this.$post('/api/demo/list', {
        pageSize: this.page.pageSize,
        pageNum: this.page.pageNum,
        status: this.filter === 0 ? undefined : this.filter
      }).then(r => {
        this.loading = false
        this.tableList = r.data.list
        this.page.total = r.data.total
      }).catch(e => {
        this.loading = false
        console.error(e)
      })
    },
    handleOffSell(id){
      // todo 封装
      this.offSell.id = id
      this.offSell.dialogVisible = true
    },
    handleOffSellSubmit(){
      // todo 封装
      this.offSell.loading = true
      this.$post('/api/demo/offSell', {
        songId: this.offSell.id,
        reason: this.offSell.reason
      }).then(r => {
        this.offSell.loading = false
        this.offSell.dialogVisible = false
        if(r.code === 0){
          message('下架成功')
          this.offSell.reason = ''
        }
        this.getData()
      }).catch(e => {
        this.offSell.loading = false
        console.error(e)
      })
    },
    getStatusByTotalStatus(id){
      return getStatusByTotalStatus(id)
    },
    handleDetail(id){
      this.$router.push('/demo-view/' + id)
    },
    handleRevoke(id){
      // todo 封装
      this.$confirm('确认要撤销吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$get('/api/demo/revoke?songId=' + id).then(() => {
          this.getData()
        }).catch(e => {console.error(e)})
      }).catch(() => {})
    },
    handleSubmit(id){
      this.$router.push('/demo/' + id)
    }
  },
  watch: {
    filter() {
      this.getData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../styles/works.scss';
.main{
  padding: 32px;
  box-sizing: border-box;
}
.off-reason-label{
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}
.off-reason{
  font-size: 10px;
  font-weight: 400;
  color: #666666;
}

</style>
